import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../../components/layout"
import SEO from "/src/components/seo"
import "./Services.scss"
import ButtonContact from "../../components/BannerFullScreen/ButtonContact"
import HoverBox from "../../components/ColoredBoxes/HoverBox"
import { Tab, Tabs } from "react-bootstrap"
import CustomAccordion from "../../components/Accordion"
import { Link } from "gatsby"

const mlopsConsultingService = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-icon-1.svg`,
        title: "Machine Learning Pipeline Development",
        content: "Leave the complexities to us. Our experts specializes in designing end-to-end pipelines, from data ingestion to model evaluation, empowering you to generate accurate predictions.",
        setting: {
            hoverClass: ' --light-yellow',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-icon-2.svg`,
        title: "Seamless Model Deployment",
        content: "Drive real-world impact with utmost simplicity. Whether you deploy ML models in on-premises or cloud-based environments, we've got you covered.",
        setting: {
            hoverClass: ' --light-blue',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-icon-3.svg`,
        title: "Continuous Delivery for Machine Learning",
        content: "Stay ahead of the curve with tailored CI/CD pipelines. Our automated testing, version control, and model versioning let you deliver updates, improvements, and bug fixes rapidly.",
        setting: {
            hoverClass: ' --green',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-icon-4.svg`,
        title: "Machine Learning Model Monitoring",
        content: "Take control of every step of the way. We maintain high-quality predictions by continuously tracking model performance metrics, data, and concept drift, ensuring optimal accuracy.",
        setting: {
            hoverClass: ' --purple',
        }
    }
]

const businessGoals = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-1.svg`,
        title: "Prioritize Success by Aligning ML Objectives with Business Goals",
        content: "Work closely to understand your broader strategic vision, define specific machine learning goals, and tailor solutions to your unique needs.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-2.svg`,
        title: "Empower Decision-making via Data Preparation and Management",
        content: "Guide you in collecting, cleaning, and preparing your data for training and evaluation. Apply best practices to optimize the data for modeling.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-3.svg`,
        title: "Unleash Cutting-edge Algorithms Model Training and Version Controlling",
        content: "Leverage cutting-edge algorithms and frameworks to train models. Employ version control techniques to manage different iterations of the models.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-4.svg`,
        title: "Ensure Reliability and Accuracy with Model Evaluation and Validation",
        content: "Employ evaluation metrics to assess the model efficacy and ensure it performs accurately in real-world scenarios.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-5.svg`,
        title: "Seamlessly Bridge the Gap from Development to Deployment",
        content: "Deploy models seamlessly in testing and production environments. Utilize containerization technologies like Docker to package models and their dependencies.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/business-goal-6.svg`,
        title: "Proactively Support for Long-term Success",
        content: "Implement robust monitoring systems that track model performance and detect data drift to identify and resolve performance issues or unexpected behavior timely.",
        setting: {
            hoverClass: ' --blue --short --hide-title-hover',
        }
    },
]

const toolsAndInfrastucture = [
    {
        title: "Source Control",
        tools: [
            {
                name: "DVC",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-dvc.jpg`
            },
            {
                name: "Git Workflow",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-git.jpg`
            },
            {
                name: "Gitlab",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-gitlab.jpg`
            },
            {
                name: "Bitbucket",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-bitbucket.jpg`
            },
            {
                name: "Github",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-github.jpg`
            }
        ]
    },
    {
        title: "CI/CD Tool",
        tools: [
            {
                name: "Jenkins",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-jenkins.jpg`
            },
            {
                name: "Gitlab",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-gitlab.jpg`
            },
            {
                name: "Circle CI",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-circleci.jpg`
            }
        ]
    },
    {
        title: "Feature Store",
        tools: [
            {
                name: "Feast",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-feast.jpg`
            },
            {
                name: "Tecton",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-tecton.jpg`
            }
        ]
    },
    {
        title: "Data Pipeline",
        tools: [
            {
                name: "Kubeflow",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-kubeflow.jpg`
            },
            {
                name: "Airflow",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-airflow.jpg`
            }
        ]
    },
    {
        title: "Oncloud",
        tools: [
            {
                name: "AWS Sagemaker Studio",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-sage.jpg`
            },
            {
                name: "Vertex AI",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-vertex.jpg`
            },
            {
                name: "Machine Learning Studio",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-mlstudio.jpg`
            }
        ]
    },
    {
        title: "Metadata Store",
        tools: [
            {
                name: "Artifact",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-artifact.jpg`
            },
            {
                name: "MLflow",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-mlflow.jpg`
            },
            {
                name: "Kubeflow Pipeline Metadata",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-kubeflow.jpg`
            }
        ]
    },
    {
        title: "Model Serving",
        tools: [
            {
                name: "Flask",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-flask-1.jpg`
            },
            {
                name: "Fast API",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-fast-api-1.jpg`
            },
            {
                name: "Seldon",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-seldon-1.jpg`
            },
            {
                name: "Bentoml",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-bentoml.jpg`
            },
            {
                name: "Kserve",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-kserve-1.jpg`
            },
            {
                name: "Triton",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-triton-1.jpg`
            },
            {
                name: "Ray",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-ray-1.jpg`
            }

        ]
    },
    {
        title: "Framework",
        tools: [
            {
                name: "MXnet",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-mxnet.jpg`
            },
            {
                name: "Pytorch",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-pytorch.jpg`
            },
            {
                name: "TensorFlow",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-tensorflow.jpg`
            },
            {
                name: "Scikit-Learn",
                img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/logo-scikit-learn.jpg`
            }
        ]
    }
]

const businessOutcome = [
    {
        title: "Harness the Knowledge of Industry Leaders",
        content: "Boast a team of seasoned Data Scientists, ML Engineers, and MLOps experts with extensive experience in designing, developing, and deploying machine learning workflows.",
    },
    {
        title: "Streamline Your Entire ML Lifecycle",
        content: "Provide end-to-end MLOps consulting services, covering every aspect of the MLOps lifecycle, from defining objectives to deploying and monitoring models."
    },
    {
        title: "Your Success, Our Priority",
        content: "Customize solutions to meet the specific needs of your business, whether you are in healthcare, finance, e-commerce, or any other industry."
    },
    {
        title: "Fuel Growth with Agile MLOps Infrastructure",
        content: "Prioritize efficiency and scalability in all our MLOps solutions, ensuring that your MLOps infrastructure can scale as your organization grows."
    },
    {
        title: "Strengthen Partnership via Continuous Support",
        content: "Commit to providing ongoing assistance, and addressing any challenges that may arise. Remain business alignment via regular communication and feedback."
    },
    {
        title: "Demonstrate Excellence with Proven Track Records",
        content: "Help organizations across various industries implement efficient ML pipelines and achieve enhanced productivity through our MLOps expertise."
    }
]

const yourModel = [
    {
        title: "Dedicated Team",
        content: ["Assemble a dedicated MLOps team exclusively for project", "Integrate with your internal team as an extension", "Benefit from a skilled team focused on your MLOps requirements", "Ensure smooth integration into your existing processes"],
        img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/icon-team.svg`
    },
    {
        title: " Team Extension",
        content: ["Augment your existing team with our MLOps specialists", "Foster collaboration and facilitate efficient knowledge transfer", "Bridge skill gaps in your team to build a well-rounded MLOps workforce", "Flexibly scale up or down based on your project's needs"],
        img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/icon-extension.svg`
    },
    {
        title: "Project-based Model",
        content: ["Collaboratively define project deliverables, timelines, and criteria", "Take complete responsibility for project deliver within agreed-upon scope", "Give a structured and result-oriented engagement model", "Provide clarity and transparency throughout the project lifecycle"],
        img: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/icon-project.svg`
    }
]

const faqs =
[
    {
        title: "Why should I opt for MLOps consulting?",
        description: "MLOps consulting allows organizations to effectively manage the complexities of deploying and scaling machine learning models in production. It ensures efficient workflows, reduces risks, improves model performance, and enables faster time-to-market for ML projects."
    },
    {
        title: "How can you help us implement a scalable MLOps infrastructure for large datasets and growing ML projects?",
        description: "We leverage cloud technologies, containerization, and automation to build robust systems capable of handling large datasets and accommodating the future growth of ML projects."
    },
    {
        title: "How do you ensure data security and compliance with industry regulations in our ML operations?",
        description: "Our MLOps solutions include access control, encryption, and secure data handling practices. We ensure compliance with relevant regulations such as GDPR and HIPAA, depending on your specific industry requirements."
    },
    {
        title: "How do you monitor and manage deployed ML models to for consistent performance and accuracy?",
        description: "We set up performance metrics, implement logging and alerting systems, and utilize automated testing frameworks to continuously monitor and evaluate the performance of deployed ML models, ensuring they maintain accuracy over time."
    },
    {
        title: "How much do your MLOps consulting services cost?",
        description: "It depends on the scope of the project, engagement models, and expertise level required that we will estimate and provide a quote that match your business goals."
    }
]

const otherServices = [
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-1.svg`,
        title: "AI Development",
        linkTo: "/services/artificial-intelligence"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-2.svg`,
        title: "AI Consutling",
        linkTo: "/services/artificial-intelligence-consulting"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-3.svg`,
        title: "Generative AI Consulting",
        linkTo: "/services/generative-ai-consulting"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-6.svg`,
        title: "Data Engineering",
        linkTo: "/services/data-engineering"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-4.svg`,
        title: "Data Science And Analytics Solutions",
        linkTo: "/services/data-science-and-analytics"
    },
    {
        icon: `${process.env.GATSBY_CDN_CONFIG}/v2/mlops/other-service-5.svg`,
        title: "Business Intelligence",
        linkTo: "/services/business-intelligence"
    },
]

const MlopsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout showHeaderBG={true}>
        <SEO title={t("MLOps Consulting Services")} description={"Unlock the full potential of MLOps with our tailored solutions for unparalleled performance and innovation. Explore our MLOps consulting services now!"}/>

        <section className="mlops-intro wrapper">
            <div className="content-background px-5" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/mlops/background-intro.jpg)`, borderRadius: '24px'}}>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-12">
                        <h1 className="h1-text text-white">Strategic <span className="light-yellow">MLOps Consulting</span> for Data-driven Success</h1>
                        <p className="mt-5 text-white">Optimize the performance and efficiency of your business’s machine learning operations via automating ML pipelines.</p>
                        <p className="mt-5"><ButtonContact item={{customClass:"--bg-yellow --font-weight-exbold", customText:"Talk to an Expert", linkTo: "/contact"}}/></p>
                    </div>
                    <div className="col-lg-5 d-none d-lg-block">
                        <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-intro.png`} alt="MLOps Consulting Services"/>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper">
            <div className="padding-y-100 content-background" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-bg.png)`, backgroundSize: 'auto', backgroundPosition:'top right'}}>
                <h2 className="col-lg-6 h2-text px-0">Revolutionize Your Workflow with <span className="primary-blue">MLOps Consulting Services</span></h2>
                <p className="col-lg-8 px-0 mt-4">Our MLOps consulting puts you in the driver's seat, enabling your business to leverage the full potential of machine learning, implement and manage ML workflows effectively.</p>

                <div className="row mt-5 m-minus-15">
                    <div className="col-lg-6 col-12">
                        <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-consulting-service-1.png`} alt="MLOps Consulting Services"/>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                        <div className="row m-minus-15">
                        {
                            mlopsConsultingService.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-12 mb-3 px-2" key={index}>
                                        <HoverBox item={item}/>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper">
            <div className="padding-y-100 pt-0">
                <h2 className="col-lg-8 h2-text px-0">Our Complete MLOps Implementation Consulting Process</h2>
                <p className="col-lg-9 px-0 mt-4">We put your success at the heart of our consulting process. Our approach guides you every step of the way, delivering tailored solutions that drive tangible business value.</p>
                <div className="row m-minus-15 mt-5">
                    {
                        businessGoals.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-sm-6 col-12 mb-3 px-2" key={index}>
                                    <HoverBox item={item}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="padding-y-100" style={{backgroundColor:"#f3f3f3"}}>
            <div className="wrapper">
                <h2 className="col-lg-7 mx-auto text-center h2-text px-0">Cutting-edge Infrastructure & Tools to <span className="primary-blue">Empower Your ML Journey</span></h2>
                <p className="col-lg-5 mx-auto text-center px-0 mt-4">Confidently navigate machine learning complexity with the right infrastructure and tools and drive real impact for your business.</p>

                <div id="mlops-tools" class="row m-minus-15 mt-5">
                    <Tabs
                        defaultActiveKey={toolsAndInfrastucture[0].title}
                        id="tab"
                    >
                        {
                            toolsAndInfrastucture.map((item, index) => {
                                return (
                                    <Tab eventKey={item.title} title={item.title} key={index}>
                                        <div className="text-center">
                                            <p className="h4-text primary-blue --font-weight-exbold col-12 px-0 text-center mb-4">{item.title}</p>
                                            {
                                                item.tools.map((tool, index) => {
                                                    return(
                                                        <div className="d-inline-block text-center mt-4">
                                                            <img className="px-4" width={'120'} src={tool.img} alt={tool.name} key={index}/>
                                                            <p className="sub-content mt-3">{tool.name}</p>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div>
        </section>

        <section className="padding-y-100 content-background" style={{backgroundColor:"#0063D7"}}>
            <div className="wrapper align-items-start">
                <h2 className="col-lg-7 text-white h2-text px-0">Accompany Neurond on Your Path to Tangible Business Outcomes</h2>
                <p className="text-white px-0 mt-4">We are committed to your success. Let our team of experts guide you towards remarkable achievements.</p>

                <div className="row m-minus-15 mt-5">
                    {
                        businessOutcome.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-sm-6 col-12 mb-3 px-2" key={index}>
                                    <div className="white-box">
                                        <h3 className="h4-text text-center primary-blue --font-weight-bold hover-colored-box__title">{item.title}</h3>
                                        <p className="mt-4 text-center">{item.content}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="wrapper">
            <div className="padding-y-100">
                <h2 className="h2-text text-center">Find Your Perfect Model</h2>
                <p className="col-lg-6 mx-lg-auto mt-4 text-center">We know every business’s needs are different. At Neurond, we offer a range of customizable options that cater to companies of all sizes and project types.</p>

                <div className="row m-minus-15 mt-5">
                    {
                        yourModel.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0" key={index}>
                                    <div className="grey-box">
                                        <p className="text-center">
                                            <img className="img-fluid" src={item.img} alt={item.title}/>
                                        </p>
                                        <h3 className="h3-text --font-weight-bold --uppercase text-center mt-4">{item.title}</h3>
                                        <ul>
                                            {
                                                item.content.map((model) => {
                                                    return(<li className="mt-4">{model}</li>)
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>

        <section className="wrapper mlops-faqs">
            <div className="padding-y-100 pt-0">
                <h2 className="h2-text text-center">FAQs</h2>

                <div className="row m-minus-15 mt-5">
                    <div className="col-lg-6 col-12">
                        <img className="img-fluid" src={`${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-faqs.png`} alt="FAQs img"/>
                    </div>
                    <div className="col-lg-6 col-12">
                        <CustomAccordion data={faqs}/>
                    </div>
                </div>
            </div>
        </section>

        <section className="wrapper mlops-supercharge">
            <div className="padding-y-100 content-background px-md-5 px-4" style={{backgroundImage: `url(${process.env.GATSBY_CDN_CONFIG}/v2/mlops/mlops-supercharge-bg.png)`, borderRadius: '20px', width:'100%'}}>
                <h2 className="h2-text text-white col-md-5 col-12 px-4">Supercharge <span className="d-md-inline-block">Your AI Deployments</span> Now!</h2>
                <p className="mt-5 px-4"><ButtonContact item={{customClass:"--bg-yellow --font-weight-exbold", customText:"Consult with Our Experts", linkTo: "/contact"}}/></p>
            </div>
        </section>

        <section className="content-background padding-y-100 mt-5" style={{backgroundImage: 'linear-gradient(0deg, #003473, #0063D7)'}}>
            <div className="wrapper">
                <div className="row m-minus-15">
                    <div className="col-12 mb-5">
                        <p className="sub-text-h2 text-white">SERVICES</p>
                        <h2 className="h2-text light-yellow">
                            Other Services
                        </h2>
                    </div>
                    <div className="col-12 px-0 row">
                        {
                            otherServices.map((item, index) => {
                                return(
                                    <div className="col-lg-4 col-md-6 col-12 mt-4" key={index}>
                                        <div className="mlops-other-service row align-items-center">
                                            <Link to={item.linkTo}><img src={item.icon} alt={item.title}/></Link>
                                            <h3 className="primary-blue h4-text --font-weight-exbold px-4 text-center"><Link to={item.linkTo}>{item.title}</Link></h3>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default MlopsPage
